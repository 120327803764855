import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
import { First } from '@kastle/ui/src/molecules/Showcases/Mobile';
import { FeatureSection } from '../../sections/FeatureSection';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "découvrez-nos-services-digitaux"
    }}>{`Découvrez nos services digitaux`}</h1>
    <First mdxType="First" />
    <FeatureSection scope="services.sections.development" mdxType="FeatureSection" />
    <FeatureSection scope="services.sections.design" mdxType="FeatureSection" />
    <FeatureSection scope="services.sections.indexing" mdxType="FeatureSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      